import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71837e2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-base" }
const _hoisted_2 = {
  key: 0,
  style: {"margin-top":"10px"}
}
const _hoisted_3 = { class: "time" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_Header, { title: "反馈记录" })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { class: "content-grey" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.data.pagedata.value, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "feedback-record-item",
                key: index
              }, [
                _createElementVNode("p", null, _toDisplayString(item.remark), 1),
                (item.dealDesc != '' && item.dealDesc != null)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_2, " 回复：" + _toDisplayString(item.dealDesc), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_3, _toDisplayString(item.createTime), 1)
              ]))
            }), 128))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}