
import {
  IonPage,
  IonHeader,
  IonContent,
  onIonViewDidEnter,
  onIonViewWillEnter,
} from "@ionic/vue";
import { onMounted, ref } from "vue";
import { getUserFeedbackList } from "@/service/member";
import Header from "@/components/header.vue";
import { userinfo, checklogin } from "@/utils/usermodule";
import { local } from "@/utils/storage.service";

export default {
  name: "FeedbackRecord",
  components: { Header, IonHeader, IonContent, IonPage },
  setup() {
    const loginuser = local.get("loginuser");
    console.log(loginuser);
    //检测是否登录
    checklogin();

    const data = {
      pagedata: ref([]) as any,
      loadStatus: ref(false),
      currentPage: 0,
      pageSize: 10,
    };

    const methods = {
      async getList() {
        if (loginuser == null || loginuser.islogin != "1") {
          return;
        }
        const { rows, page, total }: any = await getUserFeedbackList({
          userid: loginuser.userid,
          page: data.currentPage + 1,
          rows: data.pageSize,
        });
        data.pagedata.value = [...data.pagedata.value, ...rows];
        data.currentPage = page;
        data.loadStatus.value = page >= total;
      },
      refresh(event: any) {
        methods.defaultData();
        setTimeout(async () => {
          await methods.getList();
          event.target.complete();
        }, 200);
      },
      loadMore(event: any) {
        setTimeout(async () => {
          await methods.getList();
          event.target.complete();
        }, 200);
      },
      defaultData() {
        data.pagedata.value = [];
        data.currentPage = 0;
        data.loadStatus.value = false;
      },
    };

    //进入页面并且渲染完页面后触发，每次进入页面都触发
    onIonViewDidEnter(() => {
      methods.getList();
    });
    //进入页面后触发，每次进入页面都触发
    onIonViewWillEnter(() => {
      console.log("onIonViewWillEnter!");
    });

    return { data };
  },
};
